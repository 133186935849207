<template>
  <div>
    <div class="room-body-header d-f ai-c jc-sb">
      <span class="d-f ai-c">
        <common-timer v-model="dates" @change="search"></common-timer>
        <!-- <el-date-picker
          v-model="dates"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
          clearable
          @clear="search"
        > -->
        <!-- </el-date-picker> -->
        <!-- <el-time-picker style="margin-left: 5px" is-range format="HH:mm" v-model="times" range-separator="至"
          start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" @clear="search">
        </el-time-picker> -->
        <!-- <el-select
          v-model="identifyFlag"
          placeholder="请选择"
          style="margin: 0 5px; width: 100px"
        >
          <el-option
            v-for="item in roleList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
        <el-select v-model="clockFlag" placeholder="请选择" style="margin: 0 5px; width: 100px" @change="clockChange">
          <el-option v-for="item in clockList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
        <my-input placeholder="姓名、房号" v-model="name" clearable style="width: 200px; margin-right: 8px" @clear="search" />
        <el-button type="primary" @click="search"> 搜 索</el-button>
        <el-button v-if="!isMM" title="导出" @click="exportData(false)" :style="
          !(sectionItem && sectionItem.length)
            ? 'margin-left: 20px;cursor:not-allowed;opacity:.5;margin-left:16px'
            : 'margin-left: 20px;cursor:pointer;margin-left:16px'
        " :disabled="!(sectionItem && sectionItem.length)">
          保存
        </el-button>
      </span>
      <span class="d-f ai-c">
        <common-daochu v-if="!isMM" title="全部导出" @click="exportData(true)" type="text" class="icon-btn-box">
          <img style="" class="c-p icon-btn icon-btn-all" src="@/assets/daochuquanbu.svg" alt="" srcset="" />
        </common-daochu>
        <!-- <el-badge v-if="!isMM" :value="unclockNumber" class="item" style="margin-left:20px">
          <img
            @click="goToWarn"
            class="c-p"
            style="margin-left: 20px"
            src="@/assets/warning.png"
            alt=""
          />
        </el-badge> -->
        <div @click="goToWarn"  class="c-p" style="margin-left: 15px" v-if="!isMM&&isDev">
          有{{ unclockNumber }}条新告警
        </div>
      </span>
      <div v-if="isDev">
        <el-button type="primaryHover" @click="goToAnalysis">统计分析</el-button>
        <!-- <el-button
          v-if="!isMM"
          :disabled="!(sectionItem && sectionItem.length)"
          type="primary"
          @click="exportData"
          >导出</el-button
        > -->
      </div>
    </div>
  </div>
</template>

<script>
var minMax = require('dayjs/plugin/minMax')
dayjs.extend(minMax)
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("clock");
const { mapActions: settingAction } = createNamespacedHelpers("setting");
import dayjs from "dayjs";
const subDay = dayjs().subtract(6, "month");
export default {
  name: "unlock-body-header",
  data() {
    return {
      isDev: false,
      name: "",
      unclockNumber: 0,
      dates: [
        // (subDay.unix() > dayjs(`${new Date().getFullYear()}-01-01`).unix() ? subDay : dayjs(`${new Date().getFullYear()}-01-01`)).format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ],
      times: [
        new Date(dayjs().format("YYYY-MM-DD") + " 00:00:00"),
        new Date(dayjs().format("YYYY-MM-DD") + " 23:59:59"),
      ],
      identifyFlag: "", // 角色
      clockFlag: 0,
      roleList: [
        { id: 0, name: "宿管" },
        { id: 1, name: "学生" },
      ],
      clockList: [
        { id: 1, name: "已打卡" },
        { id: 0, name: "未打卡" },
      ],
    };
  },
  methods: {
    ...mapActions([
      "getTableList",
      "batAdd",
      "saveRoomInfo",
      "getUnClockNumber",
      "getUnclockExcel",
      "getClockExcel",
      "getWarnTable",
    ]),
    ...mapMutations(["setDateRange", "setSectionItem"]),
    ...settingAction(["getCalanderInfo", "getAllSettingMessage"]),
    search() {
      const { identifyFlag, clockFlag, name, dates, times } = this;
      const params = {
        identifyFlag,
        clockFlag,
        complexCondition: name,
        pageNum: 1,
      };
      if (dates && dates.length == 2) {
        params.startDate = dayjs(dates[0]).format("YYYY-MM-DD");
        params.endDate = dayjs(dates[1]).format("YYYY-MM-DD");;
      }
      // if (times && times.length == 2) {
      //   params.startTime = dayjs(times[0]).format("HH:mm:ss");
      //   params.endTime = dayjs(times[1]).format("HH:mm:ss");
      // }
      this.getTableList(params);
      this.getUnclockFunc();
      this.setSectionItem([]);
    },
    sure() {
      // 批量添加确认
      const { build, cell, room } = this.batchAdd;
      if (build && cell && room) {
        this.batchAdd(this.batchAdd);
      }
    },
    clockChange() {
      this.search();
    },
    addRoomFunc(data) {
      this.saveRoomInfo(data).then(() => {
        this.drawer = false;
      });
    },
    goToWarn() {
      this.$router.push("/clock/warnSet");
    },
    goToAnalysis() {
      this.$router.push("/clock/analysis");
    },
    getUnclockFunc() {
      this.setDateRange(this.dates);
      this.getUnClockNumber({
        startTime: this.dates[0] || "",
        endTime: this.dates[1] || "",
      });
    },
    exportData(all = false) {
      const params = all
        ? 0
        : {
          exportIds: this.sectionItem.map((it) => it.id).join(","),
        };
      this.clockFlag == 1
        ? this.getUnclockExcel(params)
        : this.getClockExcel(params);
    },
  },
  mounted() {
    this.getUnclockFunc();
    this.getAllSettingMessage()
      .then((res) => {
        if (res && res.length) {
          const alarmType = res.reduce(
            (c, n) =>
              n.paramTypeCode == "cycleSet" ? n.paramValue.split(";")[0] : c,
            ""
          );

          return this.getWarnTable({
            pageNum: 1,
            pageSize: 50,
            readFlag: 0,
            alarmType,
          });
        } else {
          return { total: 0 };
        }
      })
      .then((res) => {
        console.log(res);
        this.unclockNumber = res.total;
      });
    this.getCalanderInfo(new Date().getFullYear()).then((alls) => {
      this.times = alls.reduce(
        (c, n) =>
          c.length || !n.clockSetting
            ? c
            : n.clockSetting
              .split(";")[1]
              .split("-")
              .map((it) => new Date("2021-01-01 " + it)),
        []
      );
    });
  },
  watch: {
    visible() {
      this.batchAdd = {
        build: "", // 楼栋
        cell: "", //  楼层数量
        room: "", // 房间
      };
    },
  },
  computed: {
    ...mapState(["sectionItem"]),
    isMM() {
      return this.$store.state.login.isMM;
    },
  },
};
</script>

<style lang="scss" scoped>
.room-body-header {
  height: 60px;
  background: #fff;
  padding: 0 24px;
}

.room-header-add {
  .el-input {
    margin-bottom: 12px;
    width: 100%;
  }

  button {
    margin: 0 4px;
    width: 88px;
  }
}
</style>