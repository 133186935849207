<template>
  <div class="house-master h-100_">
    <div class="room-body h-100_ no-side">
      <clock-master-body-header />
      <div class="room-body-content w-100_">
        <common-table short :data="tableList" style="width: 100%" @selectionChange="handleSelectionChange"
          :setQuerySearchPagesize="setQuerySearchPagesize" :setQuerySearchPageNum="setQuerySearchPageNum"
          :querySearch="querySearch" :total="total" :indexWidth="4" :queryTableData="getTableList">
          <el-table-column type="selection" width="55" align="center">
          </el-table-column>
          <el-table-column label="序号" type="index" width="100">
            <template slot-scope="scope">
              {{
                (querySearch.pageNum - 1) * querySearch.pageSize +
                1 +
                scope.$index
              }}
            </template>
          </el-table-column>
          <el-table-column prop="name" width="100" label="姓名">
          </el-table-column>
          <el-table-column prop="phone" label="手机号"> </el-table-column>

          <el-table-column prop="identityNo" label="身份证号码">
          </el-table-column>
          <el-table-column prop="buildingName" label="楼栋"> </el-table-column>
          <el-table-column prop="roomNo" label="房号"> </el-table-column>
          <el-table-column prop="createTimes" label="日期">
            <template slot-scope="scope">
              {{
                (scope.row && scope.row.calendarDate ? scope.row.calendarDate : "")

              }}
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="打卡时间">
            <template slot-scope="scope">
              {{
                (scope.row && scope.row.clockTimeDate ? scope.row.clockTimeDate : "") + " " +
                (querySearch.clockFlag ? (scope.row && scope.row.clockTimeTime ? scope.row.clockTimeTime : '') : "")
              }}
            </template>
          </el-table-column>
        </common-table>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("clock");
import ClockMasterBodyHeader from "./clockMasterBodyHeader.vue";
import dayjs from "dayjs";
export default {
  name: "house-master",
  components: {
    ClockMasterBodyHeader,
  },
  data() {
    return {
      dayjs,
      showEdit: false,
      modifyVisible: false,
      modifyName: "",
      deleteVisible: false,
      current: 1,
    };
  },
  methods: {
    ...mapActions(["getTableList"]),
    ...mapMutations([
      "setSectionItem",
      "setQuerySearchSize",
      "setQuerySearchPagesize",
      "setQuerySearchPageNum",
    ]),
    handleSelectionChange(val) {
      // 多选的item
      this.setSectionItem(val);
    },
    deleteFunc() {
      //   删除
      this.deleteVisible = false;
    },
    modify(val) {
      //  修改
      console.log(val);
      this.modifyVisible = false;
    },
    finishEdit(data) {
      //  编辑完成获取数据；
      console.log(data);
    },
    edit(row) {
      // 编辑当前数据
      if (this.chooseType == "cell") {
        //
        this.modifyName = row.cellNo;
        this.modifyVisible = true;
      } else {
        this.showEdit = true;
        console.log(row);
      }
    },
    deleteF(row) {
      // 删除当前数据
      if (this.chooseType == "cell") {
        //
        console.log(row);
        this.deleteVisible = true;
      } else {
        //
        this.$message.error("无法删除，请通过洛克侠app 绑定");
      }
    },
    manager(row) {
      console.log(row);
      this.$router.push("/housemaster/manager");
    },
  },
  mounted() {
    this.setSectionItem(null);
  },
  computed: {
    ...mapState(["tableList", "chooseType", "total", "querySearch"]),
    cellsData() {
      return this.chooseType == "cell";
    },
  },
};
</script>

<style lang="scss" scoped>
.house-master {
  background: #f4f5f6;
  padding: 24px;

  .room-body {
    padding: 0;
    margin: 0;
    width: 100%;
  }
}
</style>
